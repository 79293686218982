import React, { useEffect, useState } from "react";
import confetti from "canvas-confetti";
import ReactGA from "react-ga";
import baseUrl from "../utils/baseUrl";
import axios from "axios";
import Index from "../layout/Index";
import { useNavigate } from "react-router-dom";
import { getInfo } from "../service/getInfo";
import Load from "../common/Load";
import UserMora from "../common/UserMora";
import useTimeOut from "../common/hook/useTimeOut";
import ChangePassword from "../components/changePassword";

import "../styles/welcome.css";
import LayoutMain from "../components/swiper/LayoutMain";
import { useUserMora } from "../context/userMoraContext";

const Booking = () => {
  useEffect(() => {
    var obj = document.getElementById("body");
    obj.style.removeProperty("overflow");
  }, []);

  const [urlPrice, setUrlPrice] = useState(null);
  const [allData, setAllData] = useState(false);
  // const [stateMora, setStateMora] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { setIsStateMora } = useUserMora();

  // get info user
  const getInfoUser = async () => {
    try {
      const response = await getInfo();
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        return navigate("/");
      } else {
        if (Object.keys(response.data.data.info.afiliado1).length > 0) {
          setUser(response.data.data.info);
          {
            localStorage.setItem(
              "state_mora",
              response.data.data.info.validaciones.mora
            );
          }
          setIsStateMora(response.data.data.info.validaciones.mora);
        } else {
          navigate("/usuario");
        }
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  const getPrice = async () => {
    const url = `${baseUrl}/api/users/getprice`;
    try {
      const response = await axios.get(url, {
        header: "Content-Type Aplication/json",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (response.msg === "Token invalid") {
        localStorage.clear();
        return navigate("/");
      } else {
        setUrlPrice(response.data);
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  const ValidateAllInfo = () => {
    if (
      user.afiliado1?.email?.length < 0 ||
      user.afiliado1?.titular?.length < 0 ||
      user.contracto?.ciudad?.length < 0 ||
      user.contracto?.contrato?.length < 0
    ) {
      setAllData(true);
    }
  };

  const crossLogin = async () => {
    try {
      const response = await getPrice();
      if (response.msg === "Token invalid") {
        localStorage.clear();
        return navigate("/");
      } else {
        setUrlPrice(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfoUser();
    ReactGA.pageview(window.location.pathname); //Google Analytics
    ReactGA.event({
      category: "Pagina de reserva",
      action: "Ingreso pagina de reserva",
      label: "Notificación ingreso pagina de reserva ",
    });

    crossLogin();
    confetti({
      spread: 660,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
      shapes: ["star"],
      colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
      particleCount: 50,
      scalar: 1.2,
    });
  }, []);

  useTimeOut(() => crossLogin(), 1000);
  useTimeOut(() => ValidateAllInfo(), 1200);

  return (
    <Index>
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div
            className="modal-content"
            style={{ borderRadius: "1rem", border: "none" }}
          >
            <div className="card-mora" style={{ width: "100%" }}>
              ¡Descubre el mundo con Grupo Alianza Colombia! Pero antes,
              asegúrate de estar al día y no perderte ni un solo detalle de
              nuestros increíbles servicios de viaje. 🌍✈️
            </div>
          </div>
        </div>
      </div>
      {user ? (
        <>
          {user.validaciones.chpwd === "1" ? (
            <ChangePassword />
          ) : (
            <>
              {user.validaciones.mora === "1" ? (
                <div className="row centrar">
                  <div className="mt-5 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <UserMora text={user.validaciones.mmora.slice(37)} />
                  </div>
                </div>
              ) : (
                <>
                  {urlPrice != null ? (
                    <>
                      {!allData ? (
                        <LayoutMain price={urlPrice} />
                      ) : (
                        <div className="toast fade show" role="alert">
                          <div className="toast-header">
                            <svg
                              className="bd-placeholder-img rounded me-2"
                              width="400"
                              height="20"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              preserveAspectRatio="xMidYMid slice"
                              focusable="false"
                            >
                              <rect
                                width="100%"
                                height="100%"
                                fill="#007aff"
                              ></rect>
                            </svg>
                            <strong className="me-auto">
                              Información importante
                            </strong>
                          </div>
                          <div className="toast-body">
                            Para realizar una reserva a través de nuestro portal
                            debes tener actualizados tus datos, si deseas
                            realizar actualización de tus datos comunicate a la
                            línea{" "}
                            <a
                              className="text-primary"
                              href="tel:+576015520100"
                            >
                              {" "}
                              (601)5520100
                            </a>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <Load />
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Load />
      )}
    </Index>
  );
};

export default Booking;
