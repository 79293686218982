import React, { useEffect, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { getInfo } from "../service/getInfo";
import baseUrl from "../utils/baseUrl";
import Navbar from "./Navbar";
import "../styles/change-password.css";
import { Toaster, toast } from "react-hot-toast";

const ChangePassword = () => {
  let INITIAL_USER = {
    contrato: "",
    password: "",
    newpassword: "",
    newpassword2: "",
    showPassword: false,
    sucess: false  
  };

  const [user, setUser] = useState(INITIAL_USER);
  const [info, setInfo] = useState("");

  // get info user
  const getInfoUser = async () => {
    try {
      const response = await getInfo();
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        // <Navigate to="/" replace />
      } else {
        setInfo(response.data.data.info);
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  useEffect(() => {
    getInfoUser();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function redireccionar() {
    window.location.href = "/";
    localStorage.clear();
    <Navigate to="/" replace />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (user.newpassword === user.newpassword2) {
      try {
        const url = `${baseUrl}/api/users/changepassw`;
        await axios.post(
          url,
          {
            contrato: info.contracto.contrato,
            password: info.afiliado1.cedula,
            newpassword: user.newpassword,
          },
          {
            header: "Content-Type Aplication/json",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        toast.success('La contraseña se ha cambiado correctamente.');
        setTimeout(() => {
          redireccionar();
        }, "1400");
      } catch (error) {
        toast.error('Ha ocurrido un error, intente de nuevo');
      }
    } else {
      toast.error('Las contraseñas no coinciden.');
    }
  };

  setTimeout(function () {
    INITIAL_USER = {
      contrato: info.contracto.contrato,
      password: info.afiliado1.cedula,
    };
  }, 1000);
  return (
    <section className="container">
      <Navbar />
      <Toaster />
      <main className="centrar bg-degrade">
        <div className="container py-3">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col col-xl-4">
              <div className=" card p-4 Mycard">
                <div className="centrar">
                  <img
                    src="/images/AlianzaPriority2.webp"
                    alt="Grupoa Alianza Colombia"
                  />
                </div>
                <form onSubmit={handleSubmit}>
                  <p className="m-3 text-center">
                    <strong>
                      Es necesario realizar el cambio de contraseña
                    </strong>
                  </p>
                  {user.newpassword2.length !== 0 ? (
                    <>
                      {user.newpassword !== user.newpassword2 && (
                        <p className="not-macth">
                          Las contraseñas no coinciden.
                        </p>
                      )}
                    </>
                  ) : ("")}
                  <div className="form-group">
                    <label>Nueva Contraseña</label>
                    <input
                      className="form-control"
                      name="newpassword"
                      type={user.showPassword ? "text" : "password"}
                      value={user.newpassword}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirmar contraseña</label>
                    <input
                      className="form-control"
                      name="newpassword2"
                      type={user.showPassword ? "text" : "password"}
                      value={user.newpassword2}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <input
                    className="mt-3 mycheck"
                    type="checkbox"
                    onClick={() =>
                      setUser({
                        ...user,
                        showPassword: !user.showPassword,
                      })
                    }
                  />
                  Mostrar contraseña
                  <div className="centrar">
                    <button
                      className="mt-3 btn-login"
                      type="submit"
                      disabled={user.newpassword !== user.newpassword2}
                    >
                      {user.sucess ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Cargando...
                        </>
                      ) : (
                        "Cambiar contraseña"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default ChangePassword;
